import React, {useEffect} from 'react';
import Layout from '../../../../../components/Layout';
import Seo from '../../../../../components/SEO';
import {StaticImage, getImage} from 'gatsby-plugin-image';
import QuestionSection from '../../../../../components/QuestionSection';
import {Link, useStaticQuery, graphql} from 'gatsby';
import ComingSoonForm from '../../../../../components/ComingSoonForm';
import _ from 'lodash';

// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import Okta from '../../../../../images/systems/okta.svg';
import Azure from '../../../../../images/systems/azure.svg';
import Strongdm from '../../../../../images/systems/strongdm.svg';
import Tableau from '../../../../../images/systems/tableau.svg';
import Pagerduty from '../../../../../images/systems/pagerduty.svg';
import Google from '../../../../../images/systems/google.svg';
import M365 from '../../../../../images/systems/m365.svg';
import Jira from '../../../../../images/systems/jira.svg';
import Gcp from '../../../../../images/systems/gcp.svg';
import Cloudflare from '../../../../../images/systems/cloudflare.svg';

const logosImages = {
  okta: Okta,
  azure: Azure,
  strongdm: Strongdm,
  tableau: Tableau,
  pagerduty: Pagerduty,
  google: Google,
  m365: M365,
  jira: Jira,
  gcp: Gcp,
  cloudflare: Cloudflare,
};

const NotifySection = ({connectorName}) => {
  return (
    <div className="mb-16 md:mb-24 ">
      <h2 className="mb-8 mx-auto text-2xl md:text-3xl w-[250px] md:w-full font-bold text-blue text-center">
        Get notified when this information is available
      </h2>

      <div className="text-center max-w-[726px] mx-auto justify-between px-2 md:px-0">
        <div className="flex flex-col md:flex-row justify-between items-center rounded-3xl relative max-w-[726px] mx-auto">
          <ComingSoonForm connectorName={connectorName} />
        </div>
      </div>
    </div>
  );
};

const ComingSoon = ({location}) => {
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);

  const connectorName = location?.state?.state ?? 'Systems';

  const dataImages = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {backgroundImage} = dataImages;
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  return (
    <Layout
      seo={
        <Seo
          title="Coming Soon | Trustle"
          description="Integrations. We are currently working on adding more information."
        />
      }
    >
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="pb-16 bg-top"
      >
        <section className="pt-16">
          <div className="flex items-center px-4 pb-12 md:py-8 lg:mx-auto max-w-[1360px] font-bold text-sm md:text-xl text-blue">
            <Link to="/product/integrations">
              <StaticImage
                src="../../../../../images/arrow-left.svg"
                className="w-4 md:w-8"
                alt="coming soon"
                quality={100}
              />
              <span className="pl-4 md:pl-8">See All Integrations</span>
            </Link>
          </div>
          <div className="px-4 mb-8 text-center">
            <div className="flex fle-col p-4 md:py-14 md:px-12 md:flew-row items-center max-w-[616px] backdrop-filter backdrop-blur-md bg-white bg-opacity-20  mb-2 leading-11 md:leading-12 font-bold text-xl md:text-4xl m-auto text-blue text-center rounded-2xl border border-white">
              <img className="w-16 md:w-32" src={logosImages[connectorName]} alt={`logo ${connectorName}`} />
              <span className="pl-4 md:pl-8">Coming Soon</span>
            </div>
          </div>
          <div className="max-w-5xl mx-auto text-center text-blue sm:pt-8">
            <p className="px-4 mb-6 text-xl text-center ">
              We are currently working on adding more Details on how Trustle Works with {_.upperFirst(connectorName)}
            </p>
          </div>
          <NotifySection connectorName={connectorName} />
        </section>
      </BackgroundImage>
      <QuestionSection />
    </Layout>
  );
};

export default ComingSoon;
