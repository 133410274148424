import React, {useState} from 'react';
import arrowLeftWhite from '../images/arrow-left-white.svg';
import {StaticImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';

const encode = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

const FormSoonContents = ({onSubmit, connectorName}) => {
  const [email, setEmail] = useState('');
  const systemName = connectorName;
  const formName = `request-system-${connectorName}`;
  return (
    <>
      <form
        className="w-full flex flex-col md:flex-row justify-between items-center rounded-3xl relative max-w-[726px] mx-auto"
        id={formName}
        name={formName}
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={(e) => {
          if (typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
            console.log('sending conversion data.');
            window.gtag('event', 'conversion', {
              send_to: 'AW-373340033/m2LBCPPSzJ0DEIHvgrIB',
              event_callback: window.location,
            });
          } else {
            console.log('not sending conversion data.');
          }
          fetch('/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: encode({
              'form-name': e.target.getAttribute('name'),
              email,
              systemName,
            }),
          })
            .then(() => onSubmit())
            .catch((error) => console.log(`Failed to submit the form: ${formName}.`, error));
          e.preventDefault();
        }}
      >
        <input type="hidden" name="form-name" value={formName} />
        <input type="hidden" name="systemName" value={systemName} />
        <input
          className="pl-8 rounded-[150px] border-2 focus:border-blue py-2 mb-4 md:mb-0 w-full md:leading-10"
          type="email"
          name="email"
          placeholder="company email address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="bg-gradient-to-r from-[#0091FF] via-[#005799] to-[#002761] hover:from-[#4185F4] hover:via-[#00315E] hover:to-[#00315E]     md:absolute right-3 hover:bg-[#0067C4] active:bg-[#00549F] transition duration-25 ease-in-out text-white rounded-3xl shadow font-medium md:w-[228px] lg:px-12  w-full lg:text-lg z-10 px-2 py-2 mb-3 md:py1 md:mb-0 disabled:bg-gray-300 disabled:opacity-25"
          type="submit"
          disabled={email.length < 6}
        >
          Notify Me
        </button>
      </form>
    </>
  );
};

const SubmittedContents = () => {
  return (
    <div className="my-16 md:mb-60 p-5 md:p-14 bg-cover bg-gradient-to-b from-[#002761] to-[#009DDC] rounded-2xl w-full mx-auto">
      <div className="relative text-white">
        <StaticImage
          className="md:absolute z-2 md:right-0 bottom-0 md:bottom-[-140px] max-w-[250px] block mx-auto md:max-w-none relative"
          src="../images/robot-request-submited.png"
          alt="coming soon systems"
          quality={100}
        />
        <h2 className="md:leading-[55px] font-bold text-3xl md:text-4xl text-left pb-4">Thanks!</h2>
        <p className="max-w-md mb-8 md:max-w-[260px]">We’ve got that added to our list, and we will keep you posted!</p>
        <Link to="/product/integrations" className="flex items-center mb-4 ">
          <img src={arrowLeftWhite} alt="back arrow" className="w-4 md:w-8" />
          <span className="pl-2 font-bold text-white md:pl-4">See all integrations</span>
        </Link>
      </div>
    </div>
  );
};

const ComingSoonForm = ({connectorName}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <>
      {formSubmitted ? (
        <SubmittedContents />
      ) : (
        <FormSoonContents onSubmit={() => setFormSubmitted(true)} connectorName={connectorName} />
      )}
    </>
  );
};

export default ComingSoonForm;
